<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h3>
                  Online Classroom
                </h3>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                Online Classroom

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom mt-2" v-if="!loading">
      <div class="card-body">
        <form @submit.prevent="" class="row bg-filter">
          <div class="col-lg-3">
            <v-select
                :loading="loading"
                outlined
                :items="grades"
                dense
                label="Course"
                item-text="title"
                item-value="id"
                v-model="search.course_id"
            >

            </v-select>
          </div>
          <div class="col-lg-3">
            <v-select
                :items="meeting_type"
                outlined
                dense
                item-value="value"
                item-text="title"
                v-model="search.online_medium"
                label="Meeting Type">
            </v-select>
          </div>
          <div class="col-lg-4">
            <v-btn
                class=" btn btn-primary text-white font-size-lg"
                name="phone"
                ref="phone"
                depressed
                @click="__get"
            >Filter
            </v-btn>
            <v-btn
                class=" btn btn-standard ml-2 font-size-lg"
                name="phone"
                ref="phone"
                depressed
                @click="resetFilter"
            >Reset
            </v-btn>
          </div>
        </form>
      </div>
    </div>
    <div v-if="loading" class="mt-3">
      <div class="row">
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
        <div class="col-md-3">
          <v-skeleton-loader
              type="card-heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              type="image"
          ></v-skeleton-loader>
        </div>
      </div>

    </div>
    <v-row class="mt-2" v-if="!loading">
      <div class="col-6" v-if="days_rooms.length>0">
        <div class="card">
          <div class="card-header">
            <h4>{{ search.date || "Today's" }} Classes</h4>
          </div>
          <div class="row" v-if="days_rooms.length>0">
            <v-col lg="4" md="4" cols="12" v-for="(item,index) in days_rooms " :key="index">
              <b-card header-tag="header" footer-tag="footer" style="min-height: 340px">
                <template v-slot:header>
                  <h6 class="mb-0">{{ item.title }}
                    <span v-if="item.online_medium=='zoom' || item.online_medium=='Zoom'"><img
                        src="https://img.icons8.com/color/20/000000/zoom.png"/></span>
                    <span v-if="item.online_medium=='Zoom Link'"><img
                        src="https://img.icons8.com/color/20/000000/zoom.png"/></span>
                    <span v-if=" item.online_medium=='Meet' || item.online_medium=='meet'"><img
                        src="https://img.icons8.com/color/48/000000/google-meet.png"/></span>
                    <span v-if="item.online_medium=='Teams' || item.online_medium=='teams'"><img
                        src="https://img.icons8.com/color/48/000000/microsoft-teams.png"/></span>
                  </h6>
                </template>
                <!--          <pre>{{item}}</pre>-->
                <b-card-text><b> {{ `${item.linked_subjects} [${item.linked_teacher}]` }}</b></b-card-text>
                <b-card-text>{{ item.duration }} mins Class</b-card-text>
                <b-card-text>{{ item.start_from }} - {{ item.end_to }}</b-card-text>

                <b-card-text class="small" v-if="item.date">{{ item.calendar_date }}</b-card-text>
                <b-card-text class="small" v-if="item.is_class_ongoing">
                  <span class="badge badge-success text-uppercase">on going</span>
                </b-card-text>
                <template v-slot:footer v-if="item.is_started">
                  <a href="#" class="btn btn-primary" variant="primary" @click="joinClassRoom(item.id)">Join Class</a>
                </template>

              </b-card>
            </v-col>

          </div>
          <div class="row" v-else>
            <div class="col-12 text-center">
              No Class Found
            </div>
          </div>
          <div class="row">
            <div class="col-12" v-if="total>perPage">
              <b-pagination
                  @input="__get"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
              <!--  -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 text-center" v-else>
        <div class="card">
          <div class="card-body">
            <span>No online classroom available.</span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card mb-3">
          <div class="card-body">
            <v-sheet height="64">
              <v-toolbar
                  flat
              >
                <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="prev"
                >
                  <v-icon small>
                    mdi-chevron-left
                  </v-icon>
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="next"
                >
                  <v-icon small>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-menu
                    bottom
                    right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <span>{{ typeToLabel[ctype] }}</span>
                      <v-icon right>
                        mdi-menu-down
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="ctype = 'day'">
                      <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="ctype = 'week'">
                      <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="ctype = 'month'">
                      <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="ctype = '4day'">
                      <v-list-item-title>4 days</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-toolbar>
            </v-sheet>
            <v-sheet height="400">
              <v-calendar
                  ref="calendar"
                  v-model="focus"
                  :type="ctype"
                  :event-overlap-mode="mode"
                  :events="items"
                  event-text-color="white"
                  category-show-all
                  :event-more="more"
                  @click:event="showEvent"
                  @click:date="viewDay"
                  @click:more="viewDay"
              ></v-calendar>
              <v-menu
                  v-model="selectedOpen"
                  :close-on-content-click="false"
                  :activator="selectedElement"
                  offset-x
              >
                <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    flat
                >
                  <v-toolbar
                      :color="selectedEvent.color"
                      dark
                  >

                    <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                    <v-spacer></v-spacer>

                  </v-toolbar>
                  <v-card-text>
                    <h5>{{ selectedEvent.type }}</h5>
                    <span v-html="selectedEvent.content"></span>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                    >
                      Cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-sheet>
          </div>
        </div>
      </div>
    </v-row>
    <div class="row mt-2" v-if="!loading && rooms.length<1 && days_rooms.length<1">
      <div class="col-md-12 text-center">
        <v-card class="p-20">
          <img src="@/assets/media/images/no-data-found.png">
          <h5 class="mt-10">No online classroom available. Classroom will appear once assigned to your class</h5>
        </v-card>

      </div>
    </div>
  </v-app>
</template>

<script>
import OnlineClassRoomService from "@/core/services/onlineClassRoom/OnlineClassRoomService";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";

const studentSettingService = new StudentSettingService();
const onlineClassRoomService = new OnlineClassRoomService;
export default {
  name: "OnlineClass",
  data() {
    return {
      loading: true,
      search: {
        course_id: null,
        online_medium: null,
      },
      days_rooms: [],
      grades: [],
      rooms: [],
      courses: [],
      meeting_type: [
        {value: 'zoom_link', title: 'Zoom Link'},
        {value: 'meet', title: 'Meet'},
        {value: 'teams', title: 'Teams'},
        {title: 'zoom', value: 'zoom'}
      ],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      more: true,
      page: null,
      perPage: null,
      total: null,
      mode: 'column',
      focus: '',
      ctype: 'month',
      types: ['month', 'week', 'day', '4day'],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      typeToLabel: {
        month: 'Month',
        week: 'Week',
        day: 'Day',
        '4day': '4 Days',
      },
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    items() {
      const items = [];
      this.rooms.forEach((item) => {
        console.log(item)
        let startDate = new Date(item.calendar_date).toISOString().substring(0, 10);
        items.push({
          name: item.title,
          start: startDate,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: false,
        })
      })
      return items;
    },
  },
  mounted() {
    this.__get()
    this.getAllClasses()
    this.getGrades()
  },
  methods: {
    __get() {
      // this.loading = true
      onlineClassRoomService.paginateOnlineClassRoom(this.search, this.page).then(response => {
        this.days_rooms = response.data.data
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      }).catch(err => {

      }).finally(() => {
        this.loading = false;
      })
    },
    getAllClasses() {
      this.loading = true
      onlineClassRoomService.getAllClasses(this.search).then(response => {
        this.rooms = response.data.data;
        this.loading = false;
      }).catch(err => {

      }).finally(() => {
        this.loading = false;
      })
    },
    getGrades() {
      studentSettingService.getGrades(this.currentUser.id).then(response => {
        this.grades = response.data.data;
        this.loading = false;
      });
    },
    grade_course(grade) {
      let compulsory_courses = grade.compulsory_courses_info ? grade.compulsory_courses_info : [];
      let optional_courses = grade.optional_courses_info ? grade.optional_courses_info : [];
      let courses = [...compulsory_courses, ...optional_courses];
      this.courses = courses;
    },

    resetFilter() {
      this.search = {
        course_id: null,
        online_medium: null,
      }
      this.__get()
    },
    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    viewDay({date}) {
      this.focus = date
      this.ctype = 'day'
      this.search.date = date;
      this.__get();
    },
    setToday() {
      this.focus = ''
    },

    joinClassRoom(detailId) {
      this.loading = true
      onlineClassRoomService.createLog({classroom_detail_id: detailId}).then(response => {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          window.open(response.data.meeting_url);
        }, 3000)
      }).catch(error => {
        this.$snotify.error("Something went wrong")
      }).finally(this.loading = false)
    }
  },
}
</script>

<style scoped>

</style>
