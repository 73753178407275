import Apiservice from '../api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class OnlineClassRoomService {

    #api = null;

    constructor() {
        // this.#api = `${API_URL}user/class-timetable`;
        this.#api = `${API_URL}user/student/classroom/online`;

    }

    all() {
        let url = `${this.#api}-all`;
        return apiService.get(url);
    }

    getTimeTable() {
        let url = `${this.#api}/current`;
        return apiService.get(url);
    }

    getByClass(classId) {
        let url = `${this.#api}/class/` + classId;

        return apiService.get(url);
    }

    paginate(data = {}) {
        let url = `${this.#api}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    getAllClasses() {
        let url = `${this.#api}/all/classes`
        return apiService.get(url);
    }

    paginateOnlineClassRoom(data, index) {
        let url = `${this.#api}/paginate/rooms`
        if (index)
            data.page = index;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    createLog(data){
        let url = `${this.#api}/create/log`
        return apiService.post(url, data);
    }
}
